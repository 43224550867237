import axios from 'axios';
import FormData from "form-data";
import { apiBaseURL } from "../constants";

export class ContactService {
    static async send(dto) {
        const url = `${apiBaseURL}/contact`;
        const formData = this.buildFormData(dto);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        return await axios.post(url, formData, config);
    }

    static buildFormData({ name, email, phone, services, message }) {
        const formData = new FormData();

        formData.append('fullName', name);
        formData.append('email', email);
        formData.append('phoneNumber', phone);
        formData.append('services', services);
        formData.append('message', message);

        return formData;
    }
}