import React from 'react';
import {LanguageContext} from "../../contexts/LanguageContext";
import {useNavigate} from "react-router";

const ContactAside = () => {
    const {content} = React.useContext(LanguageContext);
    const navigate = useNavigate();

    const onClick = (link) => {
        navigate(`../../${link}`);
    }


    return (
        <aside className='contact-aside'>
            <h1> {content.common.contact} </h1>
            <p> {content.pages.contact.head} </p>
            <ul className='contact-aside-list'>
                <li>
                    <div className='contact-aside-info'>
                        <svg viewBox="0 0 34 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.14534 1.7242L9.10962 0.831342C10.459 0.424242 11.9104 0.522114 13.1929 1.10669C14.4754 1.69126 15.5014 2.72258 16.0793 4.00813L17.9239 8.1117C18.421 9.21717 18.5594 10.4505 18.3199 11.6387C18.0803 12.8268 17.4748 13.9102 16.5882 14.7367L13.365 17.7421C13.3251 17.7793 13.2925 17.8234 13.2685 17.8724C12.9311 18.5617 13.4436 20.4028 15.0489 23.1849C16.8596 26.3206 18.2578 27.5599 18.906 27.3688L23.1364 26.0742C24.2949 25.7204 25.5352 25.738 26.6832 26.1245C27.8312 26.5111 28.8295 27.2471 29.5382 28.2296L32.1596 31.8599C32.9826 32.9998 33.3639 34.3997 33.2325 35.7995C33.1011 37.1993 32.4661 38.5038 31.4453 39.4706L29.19 41.6046C28.4057 42.3475 27.4533 42.8896 26.4141 43.1847C25.3749 43.4798 24.2798 43.519 23.2221 43.2992C16.9418 41.9921 11.315 36.9349 6.29355 28.2385C1.27034 19.5367 -0.295735 12.126 1.72212 6.03134C2.0599 5.01099 2.63832 4.08694 3.40851 3.33726C4.1787 2.58759 5.11803 2.03432 6.14712 1.7242H6.14534ZM6.92034 4.28849C6.30285 4.47447 5.73921 4.80636 5.27703 5.2561C4.81486 5.70585 4.46773 6.26023 4.26498 6.87241C2.52569 12.1242 3.93462 18.7956 8.61319 26.8992C13.2882 34.9974 18.3561 39.551 23.7668 40.676C24.4017 40.808 25.0591 40.7844 25.6829 40.6071C26.3067 40.4299 26.8783 40.1043 27.3489 39.6581L29.6025 37.526C30.1523 37.0055 30.4945 36.3031 30.5654 35.5494C30.6364 34.7956 30.4312 34.0417 29.9882 33.4278L27.3668 29.7956C26.9852 29.2667 26.4478 28.8705 25.8298 28.6623C25.2117 28.4542 24.5441 28.4446 23.9203 28.6349L19.6793 29.9331C17.3328 30.6313 15.1257 28.676 12.7293 24.5224C10.6989 21.0081 9.99534 18.4688 10.8632 16.6956C11.031 16.3528 11.2596 16.0438 11.5382 15.7831L14.7614 12.7778C15.239 12.3327 15.5652 11.7493 15.6943 11.1094C15.8233 10.4695 15.7488 9.80527 15.481 9.20991L13.6364 5.10813C13.3253 4.41573 12.7727 3.86027 12.082 3.54546C11.3912 3.23065 10.6095 3.17803 9.88283 3.39741L6.91855 4.29027L6.92034 4.28849Z" fill="white"/>
                        </svg>
                        <p> <a href="tel:+994998822500"> +994998822500 </a> </p>
                    </div>
                </li>
                <li>
                    <div className='contact-aside-info'>
                        <svg viewBox="0 0 40 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M33.75 0C35.4076 0 36.9973 0.65848 38.1694 1.83058C39.3415 3.00269 40 4.5924 40 6.25V26.25C40 27.9076 39.3415 29.4973 38.1694 30.6694C36.9973 31.8415 35.4076 32.5 33.75 32.5H6.25C4.5924 32.5 3.00269 31.8415 1.83058 30.6694C0.65848 29.4973 0 27.9076 0 26.25V6.25C0 4.5924 0.65848 3.00269 1.83058 1.83058C3.00269 0.65848 4.5924 0 6.25 0H33.75ZM37.5 9.9025L20.635 19.8275C20.4756 19.9211 20.2973 19.9782 20.1132 19.9946C19.929 20.011 19.7435 19.9864 19.57 19.9225L19.365 19.8275L2.5 9.9075V26.25C2.5 27.2446 2.89509 28.1984 3.59835 28.9017C4.30161 29.6049 5.25544 30 6.25 30H33.75C34.7446 30 35.6984 29.6049 36.4016 28.9017C37.1049 28.1984 37.5 27.2446 37.5 26.25V9.9025ZM33.75 2.5H6.25C5.25544 2.5 4.30161 2.89509 3.59835 3.59835C2.89509 4.30161 2.5 5.25544 2.5 6.25V7.005L20 17.3L37.5 7V6.25C37.5 5.25544 37.1049 4.30161 36.4016 3.59835C35.6984 2.89509 34.7446 2.5 33.75 2.5Z" fill="white"/>
                        </svg>
                        <p> <a target='_top' href="mailto:office@thejavachip.com"> office@thejavachip.com </a> </p>
                    </div>
                </li>
            </ul>
            <div className='contact-social-links'>
                <a href="https://www.linkedin.com/company/the-javachip/" target='_blank'> <img src={require('../../assets/icons/white-linkedin.png')} alt="linkedin icon"/> </a>
                <a href="https://www.facebook.com/thejavachipcompany/" target='_blank'> <img src={require('../../assets/icons/white-facebook.png')} alt="linkedin icon"/> </a>
                <a href="https://www.instagram.com/the.javachip.global/" target='_blank'> <img src={require('../../assets/icons/white-instagram.png')} alt="linkedin icon"/> </a>
            </div>
        </aside>
    );
};

export default ContactAside;