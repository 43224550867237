import React from 'react';
import './Button.scss';

const Button = ({ onClick, color, children, className }) => {
    return (
        <button onClick={onClick} className={`custom-button ${className}`} style={{ color }}>
            <div className='button-move'>
                <p> {children} </p>
                <svg className='button-arrow' viewBox="0 0 61 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M60.7071 8.65242C61.0976 8.2619 61.0976 7.62873 60.7071 7.23821L54.3432 0.874245C53.9526 0.48372 53.3195 0.48372 52.9289 0.874245C52.5384 1.26477 52.5384 1.89793 52.9289 2.28846L58.5858 7.94531L52.9289 13.6022C52.5384 13.9927 52.5384 14.6259 52.9289 15.0164C53.3195 15.4069 53.9526 15.4069 54.3432 15.0164L60.7071 8.65242ZM0 8.94531H60V6.94531H0V8.94531Z" fill={color} />
                </svg>
            </div>
            <svg className='button-circle' viewBox="0 0 70 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="35" cy="35.9453" r="34" stroke={color} strokeWidth="2" />
            </svg>
        </button>
    );
};

export default Button;