import React from 'react';
import ru from '../assets/text-storage/ru';
import en from '../assets/text-storage/en';
import az from '../assets/text-storage/az';

export const LanguageContext = React.createContext();

const storage = { ru, az, en }
export const languages = ['az', 'en', 'ru'];

const LanguageContextProvider = ({ children }) => {
    const _language = localStorage.getItem('language');
    const [language, setLanguage] = React.useState(languages.some((lang) => lang === _language) ? _language : 'en');

    const setValue = (value) => {
        localStorage.setItem('language', value);
        setLanguage(value);
    }

    return (
        <LanguageContext.Provider value={{
            content: storage[language],
            languages,
            value: language,
            setValue
        }}>
            {children}
        </LanguageContext.Provider>
    );
};

export default LanguageContextProvider;