export class CommonService {

    static getNumbersTo(limit) {
        return [...Array(limit).keys()]; // get array from [1 ... N]
    }

    static scrollTop() {
        window.scrollTo({ top: 0, left: 0 });
    }

    static getErrorMessage(error) {
        return error.response.data.message;
    }

    static fixDate(apiDate) {
        const partition = apiDate.split('T')[0];
        return partition.replace('-', '.').replace('-', '.').split(' ')[0];
    }
}