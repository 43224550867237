const az = {
    common: {
        services: 'Xidmətlərimiz',
        contact: 'Bizimlə əlaqə',
        about: 'Haqqımızda',
        privacy: 'Gizlilik Siyasəti',
        blog: 'Bloq',
        faq: 'Tez-tez verilən suallar',
        digital: 'Rəqəmsal Marketinq',
        web: 'Vebsaytların hazırlanması',
        mobile: 'Mobil tətbiqlərin hazırlanması',
        cyber: 'Kibertəhlükəsizlik',
        ai: 'Süni İntellekt',
        why: 'Nə əldə edəcəksiniz?',
        process: 'Hazırlanma mərhələləri',
        alert: 'Sorğunuz göndərildi...'
    },
    header: {
        home: 'Ana səhifə',
        portfolio: 'Portfolio'
    },
    footer: {
        logo: {
            head: 'Rəqəmsal Partnyorunuz',
        },
        titles: {
            quickLinks: 'Keçidlər',
            social: 'Bizi izləyin'
        },
        links: {
            work: 'Portfolio',
        }
    },
    pages: {
        blog: {
            share: 'Paylaş',
            allCategories: 'Bütün Kateqoriyalar',
            search: 'Axtar',
            recentPosts: 'Son bloqlar',
            relatedPosts: 'Oxşar bloqlar'
        },
        home: {
            titles: {
                techs: 'İstifadə etdiyimiz texnologiya',
            },
            taptomore: 'Ətraflı',
            quote: 'Qiymət təklifi al',
            introduction: {
                head: {
                    be: 'be',
                    different: 'Different'
                },
                subhead: {
                    get: 'Get',
                    more: 'more',
                    leads: 'leads',
                    boost: 'Boost',
                    rest: 'your sales'
                }
            },
            services: {
                heads: {
                    web: 'Biznesinizi Rəqəmsal Dünyada Tanıdın',
                    mobile: 'Müştərilərlə Hər Zaman Əlaqədə Qalın',
                    ai: 'Şirkətinizi Gələcəyə Hazırlayın, Rəqabətdə Öndə Olun',
                    cyber: 'Sistemlərinizi və Məlumatlarınızı Qoruyun',
                },
                texts: {
                    web: 'Rəqəmsal əsrdə milyonlarla insan bir məhsul və ya xidmət haqqında məlumat almaq üçün internetə üz tutur. Bu günlərdə yaxşı dizayn edilmiş və istifadəçi dostu veb saytla məhsul və xidmətlərinizi ən yaxşı şəkildə nümayiş etdirə bilərsiniz. İndi təsəvvür edin ki, yeni bir veb saytınız var. Minlərlə istifadəçi hər gün saytınızı ziyarət edir və dizayn, sürətli yükləmə müddəti və saytdan asan istifadə ilə heyran qalır. Sizin üçün hazırlayacağımız veb sayt məhz belə olacaqdır.',
                    mobile: 'Dünyada hər gün smartfon istifadəçilərinin sayı artır və buna görə də, biznes sahibi olaraq hər zaman müştərilərinizin olduğu yerdə olmalısınız. Rəqabətin getdikcə artması ilə təkcə vebsaytınızın olması kifayət deyil. Şirkətinizi dəqiq şəkildə əks etdirən və biznes proseslərinizlə tamamilə əlaqəli olan mobil tətbiqə ehtiyacınız ola bilər. Mobil tətbiqlə istifadəçilər alış-veriş etmək, görüş təyin etmək və ya sadəcə xidmət və məhsullar barədə məlumat ala bilərlər. Şirkətinizin mobil tətbiq xidmətlərindən istifadə edərək tətbiq istifadəçiləri daimi müştərilərinizə çevrilə bilər.',
                    ai: 'Gəlin bunu təsəvvür edək: Süni intellektlə yorucu tapşırıqların öhdəsindən gələn və böyüməyə və innovasiyaya fokuslanmağınız üçün vaxt qazandıran bir iş. Faydalı fikirlərə çıxışı olan və müvəffəqiyyəti təmin edən, əsaslandırılmış qərarlar qəbul edə bilən bir biznes. Süni intellekt artıq gələcəyə aid deyil - o, buradadır və biznesə baxış tərzimizi dəyişir. Süni intellekt həllərimiz proseslərinizi sadələşdirməyə, müştəri təcrübənizi təkmilləşdirməyə və biznesiniz haqqında dəyərli fikirlər əldə etməyə kömək edəcək.',
                    cyber: 'Şirkətinizi qurmaq üçün çox çalışmısınız. Sahibkar kimi biznesinizi kibertəhlükələrdən qorumağın vacibliyini bilirsiniz. Buna görə də, bir kiberhücumun sistemlərinizi və şirkətinizin məxfi məlumatlarını ələ keçirməsinə icazə verməməlisiniz. İndi kiberhücumların artması ilə ciddi təhlükəsizlik tədbirlərinə sahib olmaq həmişəkindən daha vacibdir. Ən yüksək səviyyəli kibertəhlükəsizlik xidmətlərinə diqqət yetirməklə müştərilərinizə onların məxfiliyinə və təhlükəsizliyinə dəyər verdiyinizi göstərin.',
                }
            },
            digital: {
                smm: {
                    head: 'Potensial müştərilərini müəyyən edərək satışları artır',
                    subhead: 'Sosial media platformaları potensial müştərilərlə əlaqə saxlamağın və brendinizi tanıtmanın ən asan yoludur..',
                    button: 'Brendini sosial mediada bizimlə tanıt'
                },
                seo: {
                    head: 'Vebsaytın optimizasiyası nəticəsində üzvi trafik qazan',
                    subhead: 'Uzunmüddətli marka dəyəri yaratmaq, hədəf auditoriyanızı genişlətmək istəyirsinizsə, vebsaytınızın optimizasiyası çox vacibdir..  ',
                    button: 'Pulsuz vebsayt auditi üçün müraciət et'
                },
                sem: {
                    head: 'SEM ilə brend şüurunu artır',
                    subhead: 'SEM, axtarış sistemi səhifələrində vebsaytınızın önə çıxarılması üçün istifadə edilən onlayn reklamdır..',
                    button: 'Reklamlarını indi yerləşdir'
                }
            },
            techs: 'İnnovativ rəqəmsal məhsul və xidmətləri müştərilərə təhvil verən komandamız hər zaman ən müasir trend və texnologiyaların axtarışındadır. ',
            end: {
                head: 'Rəqəmsal mövcudluğunu təkmilləşdir, Daha çox potensial müştəri qazan və Satışlarını artır.',
                text1: 'Vaxt itirmə',
                text2: 'Elə indi saatı kliklə və qiymət təklifi istə'
            }
        },
        services: {
            web: {
                text1: 'Bir biznes sahibi olaraq müştərilərinizi cəlb etmək və brendinizi tanıtmaq üçün müasir və funksional vebsaytın vacibliyini bilirsiniz. Doğru vebsayt sizə rəqiblərinizdən fərqlənməyə və satışlarınızı artırmağa kömək edə bilər. Vebsayt potensial müştərilərə nə etdiyiniz, şirkətinizlə necə əlaqə quracaqları və məhsul və ya xidmətlərinizi necə satın alacaqları barədə məlumat verir. Veb sayt brendinq və marketinq strategiyasının vacib hissəsidir.',
                text2: 'Buna görə də bizim veb inkişaf xidmətlərimiz sizin fərdi ehtiyaclarınıza uyğunlaşdırılıb. İstər sadə scroll sayt istəyən kiçik biznes, istərsə də mürəkkəb e-ticarət platforması tələb edən böyük müəssisə olsun, bizim bu layihələri uğurla təhvil vermək üçün yetərli təcrübəmiz var. Komandamız prosesin hər bir addımında sizinlə birgə işləyərək, şirkət vizyonunuza uyğun bir saytın hazırlanmasını təmin edəcək. Biz başa düşürük ki, yaxşı bir vebsayt sadəcə müasir dizayndan ibarət deyil. O, funksional, sürətli və responsiv olmalıdır. Məqsədimiz müştərilərinizin sevəcəyi və konversiyaya yönümlü bir veb saytınızın olmasıdır. ',
                reasons: [
                    'Sizə layiq bir vebsayt: Köhnə vebsaytlarla vidalaşmağın və biznesinizi böyüdəcək mükəmməl və müasir onlayn mövcudluqla salamlaşmağın vaxtıdır.',
                    'Rəqabət Üstünlüyü: Yaxşı dizayn edilmiş veb sayt rəqabət üstünlüyü verə bilər və nəticədə sizi öz sahənizdə arzulanan brendə çevirə bilər.',
                    'Sürətli Yüklənmə, Daha Çox Konversiya: Sürətli, responsiv veb-sayt xoşbəxt müştərilər və artan konversiya deməkdir.',
                    'Asan Saxlanma: Mürəkkəb veb sayt idarəçiliyi ilə sağollaşın, çünki bizim həllimiz vebsaytınızı yeniləməyi və saxlamağı asanlaşdıracaq.',
                    '24/7 İşləyən Vebsayt: Veb saytınız biznesiniz üçün 24/7 satış meneceri olacaq.',
                    'İstənilən Cihazda Mükəmməl Görünüş: Hazırladığımız vebsayt istənilən cihaz üçün optimizasiya olunur, ona görə də, hər bir cihazda qüsursuz görünəcək.'
                ]
            },
            mobile: {
                text1: 'Günümüzdə smartfonlar və digər texnoloji cihazlar bütün sektorlarda və bazarlarda önəmli bir yer tutmuşdur. Bilirsiniz ki, daha çox insanın hər şey üçün telefonlarına güvəndiyi bir vaxtda mobil proqrama investisiya etmək şirkətiniz üçün önəmli bir qərar ola bilər. Mobil tətbiqini qurmaqla biznesiniz bir cihazda məhdudlaşmayacaq, istənilən cihazda hər kəs üçün əlçatan olacaqdır. Tətbiq müştərilərinizin biznesinizlə əlaqə saxlamasını və ehtiyac duyduqları məhsul və xidmətlərə daxil olmasını asanlaşdıran istifadəçi dostu olmalıdır.',
                text2: 'Çoxillik təcrübəyə malik komandamız biznes ehtiyaclarınıza uyğunlaşdırılmış və gözləntilərinizi üstələyən fərdi mobil proqrama sahib olmağınıza kömək etmək üçün buradadır. Tətbiqinizin təkcə vizual olaraq heyrətamiz deyil, həm də yüksək funksional və istifadəsi asan olmasını təmin etmək üçün əlimizdən gələni edəcəyik. ',
                reasons: [
                    'App-Solute Həll: Fərdi hazırlanmış mobil proqramla biznesinizi üst səviyyəyə qaldırmağa hazır olun!',
                    'Sektorda Cool Şirkət: Unikal olmağınızla fərqlənin və müştərilərinizə mükəmməl mobil tətbiqetməniz ilə rəqabətdə sizi seçmək üçün səbəb verin.',
                    'İstifadəçi Dostu Əyləncə: Müştərilərinizin geri qayıtmasını təmin edəcək istifadəçi dostu, əyləncəli və interaktiv mobil proqrama sahib olun.',
                    'Ulduzlar Qazanmaq: Mobil tətbiq dünyanın harasında olmalarından asılı olmayaraq daha geniş auditoriyaya çatmağınıza kömək edə bilər. İstifadəçilərin verdikləri ulduzlar biznesinizi inkişaf etdirmək üçün sizə güc verəcək.',
                    'Mobil Marvel: Müştərilərinizi ildırım sürətli yükləmə müddətləri, asan naviqasiya və lazım olan bütün funksiyalarla heyran edin.',
                    'Hər Zaman Bağlantıda: Mobil proqramla şirkətiniz müştərilərinizin ovucları içində olacaq.'
                ]
            },
            ai: {
                text1: 'Təsəvvür edin ki, məlumatların daxil edilməsinə əsaslanaraq qərarlar qəbul edən, tək başına öyrənə və işləyə bilən maşınlardan ibarət komandanız var. Süni intellektlə bu arzu artıq realdır və bu imkanlar sonsuzdur! Səhiyyədən nəqliyyata, logistikadan bank işinə və pərakəndə satışdan telekommunikasiyaya qədər süni intellekt biznes əməliyyatlarını dəyişdirmək və sizi yeni uğur zirvələrinə çatdırmaq gücünə malikdir. Biznesinizin böyüməsinə və uğur qazanmasına kömək etmək üçün düzgün texnologiyalara sahib olmağın nə qədər vacib olduğunu bilirik və buna görə də sizin xidmətinizdəyik.',
                text2: 'Javachip-də biz sizə ağıllı və avtomatlaşdırılmış həllər təqdim etməklə biznes məqsədlərinizə çatmağınıza kömək edəcəyik. Ən müasir süni intellekt texnologiyalarımızla müştərilərinizə onların unikal ehtiyaclarına cavab verən fərdi təcrübə təklif edə bilərsiniz. Maşın öyrənməsi, təbii dil emalı (NLP), proqnozlaşdırma və ya optimallaşdırma təklif etdiyimiz süni intellekt həllərindəndir. Süni intellekt komandamız texnologiyanın gücündən istifadə edərək biznesinizi üst səviyyəyə qaldırmağa kömək edə biləcək innovativ həllər təqdim etməyə hazırdır. ',
                reasons: [
                    'Daha ağıllı biznes qərarları: Süni intellekt həlləri ilə instinktlərinizə yox, dəqiq məlumatlara əsaslanan qərarlara güvənməlisiniz. ',
                    'Zehni oxuma: Yaxşı, tam olaraq zehni oxumaq deyil, amma elə buna yaxındır! Fərdi təcrübələr təqdim etmək və müştəri məmnuniyyətini artırmaq üçün müştəri davranışını, seçimlərini və rəylərini təhlil edə bilərsiniz.',
                    'Artan Məhsuldarlıq: Süni intellekt sizin və komandanızın gördüyü rutin işləri avtomatlaşdıra bilər.',
                    'Keyfiyyətin yox, xərclərin azaldılması: Süni intellekt yüksək keyfiyyətli nəticələri qoruyarkən xərclərinizi azaltmağa kömək edə bilər.',
                    'Gələcək Buradadır: Rəqabətdən öndə olun və texnologiyanın gücü ilə yeni nailiyyətlər əldə edin.',
                    'Sərhədsiz İmkanlar: Süni intellekt ilə imkanlar sonsuzdur! İş proseslərinizi təkmilləşdirmək üçün çatbotlardan tutmuş proqnozlaşdırıcı analitikaya qədər yeni imkanlar şirkətinizin böyüməsinə şərait yaradacaq.'
                ]
            },
            cyber: {
                text1: 'Bugünkü rəqəmsal dünyada onlayn platformalarda mövcud olan hər bir şirkət kiberhücumların riski altındadır. Buna görə də, veb saytınız və rəqəmsal aktivləriniz həmişə qorunmalıdır. Təəssüf ki, məlumatların pozulmasının nəticələri şirkət üçün inanılmaz dərəcədə zərərli ola bilər, bu həm müştərilərinizə, həm də biznesinizə təsir edə bilər. ',
                text2: 'Amma biz kömək etmək üçün buradayıq. Biz bilirik ki, sizin diqqətiniz şirkətin inkişafı və uğur qazanmasındadır və məşğul olmaq istədiyiniz son şey kiberhücumdur. Buna görə də biz sistemlərinizi qorumaq üçün hərtərəfli kibertəhlükəsizlik xidmətləri təklif edirik. Bizim xidmətlərimizə firewalllar, anti-virus müdafiəsi, müdaxilənin aşkarlanması və digər kibertəhlükəsizlik xidmətləri aiddir. ',
                reasons: [
                    'Gecələr Rahat Yuxu: Hər an biznesinizin kiberhücumlardan qorunduğundan əmin ola bilərsiniz.',
                    'Onlayn Dünyanızı Təhlükəsiz Saxlayın: Yüksək səviyyəli kibertəhlükəsizlik həllərimizlə həssas məlumatlarınızı qoruyun.',
                    'Müştərilərinizin Gözündə Qəhrəman olun: Kibertəhlükəsizlik xidmətlərinə sərmayə qoymaqla müştərilərinə onların məxfiliyinə və təhlükəsizliyinə dəyər verdiyinizi göstərin.',
                    'Növbəti Xəbər Başlığı Olmayın: Biznesinizi kiberhücumun növbəti hədəfinə çevrilməkdən qoruya bilərsiniz.',
                    'Biznesinizi Hack-Proof edin: Bizim hərtərəfli təhlükəsizlik həllərimizlə şirkət və müştərilərinizi məlumatların pozulmasının zərərli təsirlərindən qoruyun.',
                    'Effektiv Xidmət: İstifadəsi asan, effektiv kibertəhlükəsizlik həllərimizlə hər şey sadə olacaqdır.'
                ]
            },
            digital: {
                comment: 'Saytınızın axtarış motorlarına uyğun optimizasiyası həm də brend şüurunu artırmaq, müştərilərlə əlaqələr qurmaq və öz sektorunuzda etibarlı nüfuz sahibi olmaq üçün yaxşı bir seçimdir.',
                package: 'xidmətinə daxil olanlar:',
                advantages: 'Üstünlükləri:',
                seo: {
                    advantages: [
                        'Brend etibarının artması',
                        'Axtarış sistemlərində üzvi olaraq üst sıralarda olmaq',
                        'Veb sayta artan trafik',
                        'Təkmilləşdirilmiş istifadəçi təcrübəsi',
                        'Brend şüurunun artması',
                        'Daha yaxşı istifadəçi təcrübəsi',
                        'Uzunmüddətli marketinq strategiyası',
                        'Artan potensial müştəri və satışlar',
                        'Daha yüksək investisiya gəliri',
                        'Rəqabət üstünlüyü'
                    ],
                    package: [
                        'Vebsayt auditi',
                        'SEO strategiyasının hazırlanması',
                        'Açar sözlərin təyini',
                        'Texniki SEO',
                        'Şəkillərin optimizasiyası',
                        'Schema markup ',
                        'Səhifə daxili SEO',
                        'Semantik SEO',
                        'Rəqiblərin təhlili',
                        'Məzmun optimizasiyası',
                        'Backlink auditi',
                        'Link yaradılması',
                        'SEO əsaslı bloq',
                        'Aylıq hesabat'
                    ]
                },
                smm: {
                    advantages: [
                        'Brend şüurunun artması',
                        'Təkmilləşdirilmiş müştəri əlaqəsi',
                        'Daha çox konversiya',
                        'Hədəf kütlənin daha yaxşı təyini',
                        'Brend reputasiyası',
                        'Artan potensial müştəri və satışlar',
                        'Artan müştəri məmnuniyyəti',
                        'Bazar məlumatlarının əldə olunması',
                        'Rəqabət üstünlüyü',
                        'Müştəri loyallığının yaxşılaşdırılması'
                    ],
                    package: [
                        'SMM strategiyasının hazırlanması',
                        'Aylıq məzmun planı',
                        'Biznes hesablarının yaradılması və idarə olunması',
                        'Yaradıcı postlar',
                        'Reklam kampaniyalarının yaradılması',
                        'Zənglər və mesajlar üçün avtomatlaşdırılmış cavab sistemi',
                        'Professional foto və video çəkilişi',
                        'Müxtəlif yarışların təşkili',
                        'Aylıq hesabat'
                    ]
                },
                sem: {
                    advantages: [
                        'Veb-sayt trafikinin artması',
                        'Xərc baxımından səmərəli',
                        'Hədəf auditoriyasına uyğun reklam',
                        'Ölçülə bilən nəticələr',
                        'Brend şüurunun artması',
                        'Artan konversiya ',
                        'Daha yaxşı istifadəçi təcrübəsi',
                        'Rəqabət üstünlüyü',
                        'Reklam büdcəsinə nəzarət',
                        'Uzunmüddətli faydalar'

                    ],
                    package: [
                        'Açar sözlərin araşdırılması və analizi ',
                        'Rəqiblərin reklam kampaniyalarının analizi və təhlili',
                        'Reklam kampaniyalarının yaradılması ',
                        'Reklam kampaniyalarının monitorinqi və optimizasiyası',
                        'Reklam üçün A\\B testi',
                        'Performans hesabatlarının hazırlanması'
                    ]
                }
            }
        },
        about: {
            introduction: {
                text1:
                    'Javachip MMC müxtəlif ölçülü bizneslər üçün rəqəmsal həllər təmin etmək məqsədilə yaradılmışdır. Biz ən son və ən yaxşı texnologiyalardan istifadə edərək öz rəqəmsal xidmətlərimizi təqdim edən müştəri yönümlü şirkətik.',
                text2:
                    'Müştərilərimizin onlayn uğur qazanmasına kömək edəcək keyfiyyətli, sərfəli və istifadəçi dostu rəqəmsal məhsullar təqdim edirik. Javachip xüsusi hazırlanmış həllər ilə biznes ehtiyaclarınızı həll etmək üçün fərdi yanaşma təklif edir.',
                text3:
                    'Bilik və təcrübəmizlə ən yaxşı həllər və xidmətlər təqdim etməyə qadirik. Fərdi ehtiyaclarınızı anlamaq və bu ehtiyaclara cavab verən həll yolu hazırlamaq üçün sizinlə sıx əməkdaşlıq etməyə hazırıq.',
                headline:
                    'Biznesinizin rəqəmsal əksinin etibarlı əllərdə olduğundan əmin olun, çünki bu, potensial ' +
                    'müştərilərinizin xidmət və məhsullarınızla tanış olacağı ilk yerdir.',
                mission: {
                    beginning: 'Missiyamız',
                    rest:
                        'bizneslərə, fiziki şəxslərə və qeyri-kommersiya təşkilatlarına öz brendlərini tanıtmaqda və bütün dünyada ' +
                        'geniş potensial müştərilərə çatmaqda kömək edəcək rəqəmsal xidmətlər təqdim etməkdir.'
                },
                vision: {
                    beginning: 'Vizyonumuz',
                    rest:
                        'davamlı innovasiya və uzunmüddətli müştəri əlaqələri quraraq dünyanın aparıcı rəqəmsal həllər ' +
                        'təminatçısı olmaqdır.'
                }
            },
            why: 'Niyə biz',
            ourTeam: 'Bizim komanda',
            reasons: {
                team: {
                    title: 'Arzuladığın komanda',
                    text:
                        'Komandamızın hər bir üzvü ən yeni texnologiyalardan istifadə edərək istənilən çətinlikdə olan layihənizi hazırlama bacarığına malikdir.'
                },
                schedule: {
                    title: 'Vaxtında təslimat',
                    text:
                        'Layihələrin planlanması zamanı, biz agile metodologiyasından istifadə edirik. Müştərilərimizə istədikləri sifarişi müqaviləyə əsasən tam zamanında ən keyfiyyətli şəkildə təqdim edirik.'
                },
                security: {
                    title: 'Münasib qiymət',
                    text:
                        'Təqdim etdiyimiz bütün rəqəmsal məhsul və xidmətlər, istər kiçik və yeni yaranmış biznes, istərsə də böyük korporativ şirkət olmağınızdan asılı olmayaraq, biznesiniz üçün münasib qiymətlərlədir.'
                },
                cost: {
                    title: 'Effektiv kommunikasiya',
                    text:
                        'Müştərilərimizlə hər zaman effektiv ünsiyyət qurmağımız vacibdir. Bu, onlara ən yaxşı xidməti göstərməyə və ehtiyaclarının ödənilməsini təmin etməyə imkan verir.'
                },
                dev: {
                    title: 'Sizə uyğunlaşdırılmış',
                    text:
                        'Hər bir şirkətin unikal olduğunu bilirik və hamımız fərdi ehtiyaclarınıza uyğun xüsusi proqram təminatını təqdim etmək üçün buradayıq.'
                },
                assistance: {
                    title: 'Dəstək',
                    text:
                        'Öz sahələrində professional olan komandamızın hər bir üzvü, sifarişinizi təslim etdikdən sonra yaranan bütün sual və problemlərinizi ən qısa müdddətdə həll etməyə hazırdır.'
                }
            },
            team: {
                huseynli: 'İcraçı Direktor',
                atayev: 'Biznesin İnkişafı Şöbəsinin Müdiri',
                rahimov: 'Full stack Veb Developer',
                tagizade: 'Full stack Veb Developer',
                karimli: 'Süni İntellekt Komanda Rəhbəri',
                salimov: 'Süni İntellekt Mühəndisi',
                alakbarli: 'Süni İntellekt Mühəndisi',
                atakishiyeva: 'Rəqəmsal Marketinq Meneceri',
                guliyeva: 'Mobil Proqram Developer',
                maharramova: 'UI/UX Dizayner',
                aliyeva: 'Qrafik Dizayner',
                sayilova: 'SMM Menecer'
            }
        },
        contact: {
            head: 'Zəhmət olmasa sorğunuzla bağlı məlumatları forma doldurun. Sizinlə əməkdaşlıq etməkdən məmnun olarıq.',
            form: {
                name: 'Adınız',
                email: 'E-poçt adresiniz',
                phone: 'Mobil nömrəniz',
                services: 'Xidmətlər',
                message: 'Sorğunuzu daxil edin',
                button: 'Göndər'
            },
            title: 'Biz buradayıq'
        },
        faq: {
            titles: {
                1: 'Siz vebsaytları qurarkən şablonlardan (template) istifadə edirsiniz?',
                2: 'Yalnız yerli bizneslərlə işləyirsiniz?',
                3: 'Süni intellekt xidmətlərinizə nələr daxildir?',
                4: 'Mobil tətbiqimi qurdurub daha sonra ondan pul qazana bilərəm?',
                5: 'Vebsaytımdan necə istifadə etməyimi öyrədəcəksinizmi?',
                6: 'Davamlı dəstək, texniki xidmət və yeniləmələr təqdim edirsiniz?',
                7: 'Süni intellekt çatbotları müştəri xidmətlərində mənə necə kömək edə bilər?',
                8: 'Veb dizaynlarınız responsivdir? ',
                9: 'Sizi fərqli edən nədir?'
            },
            texts: {
                1: 'Biz heç vaxt şablonlardan istifadə etmirik.  Javachip şirkətinin texniki komandası sıfırdan istəyinizə uyğun vebsaytları və digər rəqəmsal məhsulları hazırlayır',
                2: 'Xeyr. Biz yerli və həmçinin qlobal bizneslərlə işləyirik və rəqəmsal məhsullarımızı beynəlxalq müştərilərə çatdırmaqdan məmnun oluruq. Bütün proses boyu komandamız sizin komandanızla mümkün qədər effektiv ünsiyyət qura bilməsi üçün konfrans zəngləri, video söhbətlər və ekran paylaşımı həyata keçirməkdən məmnundur.',
                3: 'NLP əsaslı Çatbotlar, Proqnozlaşdırıcı Analitika, Maşın Öyrənmə, NLP, IVR avtomatlaşdırması təqdim etdiyimiz süni intellekt həllərindən bəziləridir.',
                4: 'Bəli. Siz onu ödənişli tətbiq kimi sata, tətbiqaxili alışlar təklif edə və ya reklamlardan pul qazana bilərsiniz.Hansı metodu seçməyinizdən asılı olmayaraq, insanların istifadə etmək istəyəcəyi bir mobil tətbiqə sahib olduğunuzdan əmin olmalısınız. Hardan başlayacağınızdan əmin deyilsinizsə, narahat olmayın - biz kömək edə bilərik.',
                5: 'Əlbəttə!  Veb saytınızı hazırlayıb tamamladıqdan sonra sizə CMS-dən (məzmun idarəetmə sistemi) necə istifadə edəcəyinizi öyrədəcəyik.  Bu, sizə heç bir kodlaşdırma bilmədən vebsaytınızda dəyişikliklər etməyə imkan verir.',
                6: 'Bəli, biz bütün müştərilərimizə davamlı dəstək və yeniləmələr təklif edirik! Biz həmçinin əlavə ödənişlə aylıq və illik texniki dəstək də təklif edirik.',
                7: 'Süni intellekt çatbotları avtomatlaşdırılmış müştəri xidməti təmin etməklə gözləmə müddətlərini azaltmağa kömək edir. Həmçinin daha dəqiq məlumat verə bilirlər. Bunun səbəbi, onların geniş məlumat mənbələrinə daxil ola bilməsidir. Bu o deməkdir ki, onlar müştərilərin suallarına dəqiq və aktual cavablar verə bilərlər.',
                8: 'Qurduğumuz bütün vebsaytlar responsivdir.  Bu o deməkdir ki, onlar istənilən ekran ölçüsündə qüsursuz işləyir',
                9: 'Biz bütün layihələrimizə son dərəcə diqqətli və dəqiqliklə yanaşırıq. Müştərilərimizə ən yaxşı sistemləri qabaqcıl texnologiyadan istifadə etməklə sərfəli qiymətlərlə təqdim edirik. Müştəri məmnuniyyəti və şirkətimizin nüfuzu bizim üçün hər şeydir!'
            },

        },
        privacy: {
            head: 'Məlumatlarınızın məxfiliyi bizim üçün vacibdir',
            subhead: 'Javachip MMC şəxsi məlumatlarınızın məxfiliyini, təhlükəsizliyini və düzgünlüyünü qorumağa sadiqdir. Bu məqsədlə Javachip MMC müştərilərinin, işçilərinin və başqalarının məxfilik hüquqlarını qorumaq üçün Məxfilik Siyasəti yaratmışdır. Bu siyasət şirkət, onun tərəfdaşları və işçiləri tərəfindən şəxsi məlumatların toplanması, istifadəsi və açıqlanması ilə əlaqədardır.',
            consent: {
                title: 'Razılığınız',
                text: 'Bu Məxfilik Siyasəti Vebsayt şəbəkəmiz (www.thejavachip.com), emal və istifadə təcrübələrimiz vasitəsilə məlumatların toplanmasımızı tənzimləyir. O, həmçinin şəxsi məlumatlarınızın istifadəsi, əldə edilməsi və düzəldilməsi ilə bağlı seçimlərinizi təsvir edir. Vebsaytdan istifadə etməklə siz bu Məxfilik Siyasətində təsvir olunan məlumat təcrübələrinə razılıq verirsiniz. Biz əldə etdiyimiz məlumatı yalnız bu Məxfilik Siyasətinə uyğun olaraq istifadə edirik. Şəxsi məlumatlarınızı heç vaxt üçüncü tərəfə satmayacağıq. Bu Məxfilik Siyasətində təsvir edilən məlumat təcrübələri ilə razı deyilsinizsə, Vebsaytdan istifadə etməməlisiniz.'
            },
            disclaimer: {
                title: 'Şəxsi məlumat',
                text: 'Bizə könüllü olaraq təqdim etdiyiniz və şəxsiyyətinizi müəyyənləşdirən məlumatlar adınız, e-poçt ünvanınız, telefon nömrəniz və özünüz və ya biznesiniz haqqında digər məlumatlardır. Naviqasiya Məlumatı kompüteriniz və bu Veb-sayta səfərləriniz, məsələn, IP ünvanınız, coğrafi yeriniz, brauzerinizin növü, istinad mənbəyiniz, ziyarət müddətiniz və baxılan səhifələr haqqında məlumatlara aiddir.'
            },
            informationUse: {
                title: 'Məlumatınızın Digər İstifadəsi',
                text: 'Özünüz haqqında təqdim etdiyiniz məlumatlardan xidmət göstərmək üçün istifadə edirik. Xidmətlərimizə dair sorğunuzu tamamlamaq üçün lazım olan hədlər istisna olmaqla, biz bu məlumatı kənar tərəflərlə paylaşmırıq. Javachip etibarlı çağırış və ya məhkəmə qərarı əsasında bu məlumatı açıqlamaq hüququnu özündə saxlayır. Texniki dəstək, müştəri xidməti, e-poçt yeniləmələri üçün aldığımız e-poçta cavab vermək üçün geri qaytarılan e-poçt ünvanlarından istifadə edirik. Bu cür ünvanlar başqa məqsədlər üçün istifadə edilmir və kənar şəxslərlə paylaşılmır. Biz heç vaxt onlayn, telefon və ya şəxsən bizə təqdim olunan şəxsiyyəti müəyyənləşdirən məlumatı yuxarıda təsvir olunanlarla əlaqəsi olmayan üsullarla istifadə etmirik və ya paylaşmırıq, eyni zamanda sizə bu cür əlaqəsiz istifadələri imtina etmək və ya başqa şəkildə qadağan etmək imkanı vermirik.'
            },
            informationSecurity: {
                title: 'İnformasiyanın Təhlükəsizliyi',
                text: 'İcazəsiz girişin qarşısını almaq, məlumatların düzgünlüyünü qorumaq və məlumatların düzgün istifadəsini təmin etmək üçün biz onlayn və oflayn olaraq topladığımız məlumatı qorumaq və qorumaq üçün müvafiq fiziki, elektron və idarəetmə prosedurlarını tətbiq etmişik. Bütün onlayn kredit kartı əməliyyatları təhlükəsiz SSL bağlantısı vasitəsilə yalnız təhlükəsiz serverlərdə həyata keçirilir və ən son standartlara uyğundur. Müştəri ilə layihənin dayandırılması ilə kredit kartı məlumatları da daxil olmaqla, bütün faktura məlumatları silinir.'
            },
            links: {
                title: 'Digər saytlara keçidlər',
                text: 'Bu vebsayt üçüncü tərəfin vebsaytlarına keçidlər təqdim edə bilər. İstənilən bu cür bağlantılar yalnız sizin rahatlığınız üçün verilir. Javachip bu vebsaytlara və ya onların məzmununa nəzarət etmir və bu vebsaytlar üçün heç bir məsuliyyət və ya öhdəlik daşımır. Javachip bu vebsaytlar və ya orada tapılan hər hansı məlumat, material və ya məhsullar haqqında heç bir açıqlama vermir və ya təsdiq etmir. Bu veb-saytda əlaqəsi olan üçüncü tərəf veb-saytlarından hər hansı birinə daxil olursunuzsa, riski öz üzərinizə götürürsünüz.'
            },
            terms: {
                title: 'Uşaqların məxfiliyi',
                text: 'Uşaqların məxfiliyinin qorunması xüsusilə vacibdir. Bu səbəbdən biz heç vaxt 13 yaşdan aşağı olduğunu bildiyimiz şəxslərdən məlumat toplamırıq və saxlamırıq və vebsaytımızın heç bir hissəsi 13 yaşından kiçik hər kəsi cəlb etmək üçün qurulmayıb.'
            },
            access: {
                title: 'Şəxsi məlumatlarınıza necə daxil ola bilərsiniz?',
                text: 'İstənilən vaxt info@thejavachip.com ünvanında bizimlə əlaqə saxlayaraq sizdən onlayn topladığımız şəxsi məlumatları daha sonra nəzərdən keçirə və ya yeniləyə bilərsiniz.'
            },
            contact: {
                title: 'Əlaqə məlumatı',
                text: 'Şəxsi məlumatlarınız və ya məxfilik siyasətimizlə bağlı hər hansı sualınız varsa, zəhmət olmasa bizimlə əlaqə saxlayın: info@thejavachip.com'
            },
            changes: {
                title: 'Məxfilik Siyasətində Dəyişikliklər',
                text: 'Javachip yenidən işlənmiş Məxfilik Siyasətini saytlarımızda yerləşdirməklə bu Məxfilik Siyasətini istənilən vaxt sizə əvvəlcədən xəbərdarlıq etmədən dəyişdirə bilər. Bu Məxfilik Siyasətini vaxtaşırı nəzərdən keçirməyinizi tövsiyə edirik.'
            }
        }
    }
}

export default az;