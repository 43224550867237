const en = {
    common: {
        services: 'Our Services',
        contact: 'Contact Us',
        about: 'About Us',
        privacy: 'Privacy Policy',
        blog: 'Blog',
        faq: 'FAQs',
        digital: 'Digital Marketing',
        web: 'Web Development',
        mobile: 'Mobile App Development',
        cyber: 'Cybersecurity',
        ai: 'Artificial Intelligence',
        why: 'What will you get?',
        process: 'Our Process',
        alert: 'Message was sent...'
    },
    header: {
        home: 'Home',
        portfolio: 'Portfolio'
    },
    footer: {
        logo: {
            head: 'Your Digital Partner',
        },
        titles: {
            quickLinks: 'Quick Links',
            social: 'Follow us'
        },
        links: {
            work: 'Portfolio',
        }
    },
    pages: {
        blog: {
            share: 'Share',
            allCategories: 'All Categories',
            search: 'Search',
            recentPosts: 'Recent Blogs',
            relatedPosts: 'Related Blogs'
        },
        home: {
            titles: {
                techs: 'Technologies we use',
            },
            taptomore: 'Learn More',
            quote: 'You\'re one step closer to your dream project. Request a Quote',
            introduction: {
                head: {
                    be: 'be',
                    different: 'Different'
                },
                subhead: {
                    get: 'Get',
                    more: 'more',
                    leads: 'leads',
                    boost: 'Boost',
                    rest: 'your sales'
                }
            },
            services: {
                heads: {
                    web: 'Step into the Future with a Website That Wows',
                    mobile: 'Put Your Business in the Palm of Their Hand',
                    ai: 'Future-Proof Your Business and Stay Ahead of the Competition',
                    cyber: 'Sleep Soundly at Night with Cyber Security That\'s Got Your Back',
                },
                texts: {
                    web:
                        'Say goodbye to the yellow pages because, in the digital age, people don\'t reach for the phonebook when they need to find a business. They reach for their smartphones. With a well-designed and user-friendly website, you can showcase your products and services in the best possible light. Now picture that you\'ve just launched your brand new website and the traffic is flooding in. Your customers are amazed by the design, lightning-fast load times, and effortless user experience. This is the kind of website we can build for you.',                         
                    mobile:
                        'The world is becoming increasingly mobile, and as a business owner, you need to be where your customers are. With more and more people using their smartphones for everything, a mobile app can be a game-changer for your business. With a mobile app, your customers will have easy and convenient access to your business, whether they need to make a purchase, book an appointment, or just get some information. A great mobile app will keep your customers engaged and coming back for more. Who knows, they may even tell their friends and family about your business!',
                    ai:
                        'Imagine this: A business that runs like a well-oiled machine, with AI taking care of the tedious tasks and freeing up time for you to focus on growth and innovation. A business that has access to valuable insights and can make informed decisions that drive success. Artificial Intelligence is no longer a thing of the future - it\'s here, and it\'s changing the way we do business. Our AI solutions will help you streamline your processes, enhance your customer experience, and gain valuable insights into your business. Let\'s harness the power of AI today!',
                    cyber:
                        'You\'ve worked hard to build your business. As a business owner, you know the importance of protecting your business from cyber threats. Don\'t let a security breach bring everything crashing down. With the rise of cyber attacks, it\'s more critical than ever to have robust security measures in place. Invest in your peace of mind and protect your business with our Cyber Security services.',
                }
            },
            digital: {
                seo: {
                    head: 'Optimize your website and generate organic traffic',
                    subhead: 'Search engine optimization is a process of optimizing your website to rank higher in search engine results pages..',
                    button: 'Get your FREE website audit now'
                },
                smm: {
                    head: 'Reach out your potential customers and increase your sales',
                    subhead:
                        'Social media platforms are the easiest way to reach out to potential customers and spread awareness about your company.. ',
                    button: 'GROW your business with us'
                },
                sem: {
                    head: 'Increase your Brand Awareness',
                    subhead:
                        'SEM is the online advertising that is used to increase the visibility of a website on search engine result pages..',
                    button: 'Place your ads NOW'
                }
            },
            techs:
                'We\'rе always on the hunt for new teсhnologies that can help us work smarter, faster, and better, and we\'rе always pushing thе boundaries of what\'s possiblе.',
            end: {
                head: 'Improve your digital presence, Get more leads, and Boost your business',
                text1: 'Do not waste your time',
                text2: 'Click o’clock and send us your wish list'
            }
        },
        services: {
            web: {
                text1:
                    'As a business owner, you know the importance of a professional and functional website for attracting customers and growing your brand. That\'s why our web development services are customized to meet your specific needs. Whether you arе a small business looking for a simple brochure site or a large enterprise requiring a complex e-commerce platform, we have the expertise and experience to deliver outstanding results. Our team of experts will work closely with you every step of the way, ensuring that your vision is realized, and your website is a true rеflection of your business.',
                text2:
                    'We understand that a great website is more than just an attractive design. It needs to be functional, fast, and responsive. Our team of developers is skilled in all aspects of website development, including design, programming, and optimization. We will еnsure that your website not only looks great but also functions flawlessly across all devices and plаtforms. At the end of the dаy, our goal is to hеlp you create a website that your customers will love. We want your website to be an extension of your brand, a place where customers can connect with your business, and a plаtform to showcase your products and services.',
                reasons: [
                    'A Website You Deserve: Say goodbye to bland, outdated websites and hello to a professional, modern online presence that will make your business shine.',                 
                    'Be the Envy of Your Competitors: A well-designed website can give you a competitive edge in your industry and make you the go-to business in your niche.',
                    'Load Faster, Convert More: A fast, responsive website means happy customers and increased conversions.',
                    'Easy Peasy Maintenance: Say goodbye to complicated website management, our solution makes updating and maintaining your website a breeze.',
                    'A Website That Works for You 24/7: Your website will be a 24/7 salesperson for your business, so you can focus on doing what you do best.',
                    'Works on Any Device: Your customers are on the go, so make sure your website is too. Our solution is optimized for any device, so you never miss a beat.'
                ]
            },
            mobile: {
                text1:
                    'The world is always on the move, and we know it can be challenging to keep up. But, have no fear, we have a solution that can take your business to the next level! We all know that smartphones are the way of the future, and with more people relying on their phones for just about everything, having a mobile app can be a game-changer for your business. Not just any app, mind you, but a beautiful and user-friendly one that makes it easy for your customers to interact with your business and access the products and services they need.',
                text2:
                    'That\'s where we come in! Our team of expert developers is here to help you create a custom mobile app that is tailored to your needs and exceeds your expеctations. We\'ll wоrk with you closely to ensure that your app is not only visually stunning but also highly functional and easy to use. We believe that your customers deserve the best, and our mobile app development services will hеlp you give them just that. By hаving an app that is accessible on the go, you can reach your customers wherever they are, and keep them engaged with your business. With our mobile app development services, you can take your business to new heights and connect with your customers like never before. ',
                reasons: [
                    'The App-solute Solution: Get ready to take your business to the next level with a custom-built mobile app!',
                    'Be the Cool Kid on the Block: Stand out from the crowd and give your customers a reason to choose you over the competition with a sleek and stylish mobile app.',
                    'User-Friendly Fun: Say goodbye to clunky websites and hello to a user-friendly, fun and interactive mobile app that will keep your customers coming back for more.',
                    'Reach for the Stars: A mobile app can help you reach a wider audience, no matter where they are in the world, giving you the power to expand your reach and grow your business.',
                    'The Mobile Marvel: Impress your customers with lightning-fast loading times, easy navigation, and all the features they need right at their fingertips.',
                    'Always Connected: With a mobile app, your business will be right at your customers\' fingertips. And who knows, they may never let go!',
                    
                ]
            },
            ai: {
                text1:
                    'Imagine having a team of machines that can learn and work on their own, making informed deсisions based on data input. With AI, this dream is now a reality, and the possibilities are endless! From healthcare to transport, logistics to banking, and retailing to telecommunications, AI has the power to transform your business operations and take you to new heights of success. As a business owner, we know how important it is to have the right technology and tools to help your business grow and succeed, and that\'s where we come in.',
                text2:
                    'At Javachip, we\'re passionate about helping you aсhieve your business goals by providing you with intеlligent and automatеd solutions. With our cutting-edge AI technologies, you can now offer your customers a personalized experience that caters to their unique preferenсes and nееds. Whether you need machine learning, computer vision, natural language processing (NLP), forеcasting, or optimization, we\'vе got you covered! Our team of AI experts is passionate about delivering innovative solutions that can help take your businеss to the next level. Let us help you unlock the powеr of technology and unleash your full potential!',
                reasons: [
                    'Smarter Business Decisions: With AI solutions, you can say goodbye to gut instincts and hello to data-driven decisions. AI can analyze vast amounts of data to help you make informed decisions about your business.',
                    'Mind Reading: Okay, not exactly mind reading, but close! With AI, you can analyze customer behavior, preferences, and feedback to provide personalized experiences and improve customer satisfaction.',
                    'Increased Productivity: AI can automate many routine tasks freeing up time for you and your team.',
                    'Cut Costs, Not Quality: AI solutions can help you cut down on costs while still maintaining high-quality results. Imagine never having to pay overtime again!',
                    'The Future is Here: Be ahead of the game and embrace the future with AI solutions. Stay ahead of the competition and be on top of the latest advancements in technology.',
                    'The Sky\'s the Limit: With AI, the possibilities are endless! From chatbots to predictive analytics, the sky is truly the limit when it comes to improving your business processes with AI solutions.'
                ]
            },
            cyber: {
                text1:
                    'It\'s important to keep in mind that in today\'s digital world, every company with an online presence is at risk of cybercrime. It doesn\'t matter how big or small your orgаnizаtion is, your website and digital assets are always vulnerable. Unfortunately, the consequences of a data breach can be incredibly damaging, impасting both your customers and your business. It\'s alarming to think about the millions of people whose personal information has been compromised due to cyber-attacks, which have resulted in billions of dollars in dаmаges.',
                text2:
                    'But don\'t worry! We\'re here to help. Wе know that as a business owner, you\'re focused on growing and thriving, and the last thing you want to deal with is a cyber-attack. That\'s why we offer comprehensive cyber security services to protect your systems. Our services include everything from firewalls to anti-virus proteсtion to intrusion detection and morе. With our help, you can rest assured that your business is protected from cyber threats and that you cаn focus on what really matters - growing your business.',
                reasons: [
                    'Sleep Tight, Don\'t Get Hacked: Protect your business and customers from cyber threats and sleep better at night knowing you\'re secure.',
                    'Keep Your Online World Safe: Protect your sensitive information and keep cyber threats at bay with our top-notch cyber security solutions.',
                    'Be a Hero to Your Customers: Show your customers that you value their privacy and security by investing in top-notch cyber security services.',
                    'Don\'t Be the Next Headline: Stay ahead of the game and protect your business from becoming the next target of a cyber attack.',
                    'Hack-Proof Your Business: Keep your business and customers safe from the damaging effects of a data breach with our comprehensive security solutions.',
                    'Cyber Security, Not Rocket Science: Keep things simple with our easy-to-use, yet effective, cyber security solutions.'
                ]
            },
            digital: {
                comment:
                    'Billions of searches are conducted online by internet users every single day. And if your ' +
                    'website is well optimized for search engines like Google, Bing, Yandex, etc., you will get an immense amount of specific, ' +
                    'high-intent traffic regularly.',
                package: 'Package',
                advantages: 'Advantages',
                smm: {
                    advantages: [
                        'Increased brand awareness',
                        'Improved customer engagement',
                        'Increased website traffic',
                        'Better targeting of your audience',
                        'Improved brand reputation',
                        'Increased leads and sales',
                        'Improved customer satisfaction',
                        'Better return on investment',
                        'Competitive advantage',
                        'Improved customer loyalty'
                    ],
                    package: [
                        'Customized SMM strategy',
                        'Monthly content plan',
                        'Creating and managing business accounts',
                        'Informative and creative posts',
                        'Creating the ad campaigns',
                        'Automated response system for incoming calls and messages',
                        'Professional photo and video shooting',
                        'Organizing engaging competitions',
                        'Monthly report'
                    ]
                },
                seo: {
                    advantages: [
                        'Increased website visibility',
                        'Higher search engine ranking',
                        'Increased traffic to your website',
                        'Improved user experience',
                        'Increased brand awareness',
                        'Better user experience',
                        'Long-term marketing strategy',
                        'Higher ROI',
                        'Increased leads and sales',
                        'Competitive advantage'
                    ],
                    package: [
                        'Website audit',
                        'Customized SEO strategy',
                        'Keyword research and analysis',
                        'Technical SEO',
                        'SEO-based blogs',
                        'Image optimization',
                        'Schema markup',
                        'On-page SEO',
                        'Semantic SEO',
                        'Competitor analysis',
                        'Content optimization',
                        'Backlink audit',
                        'Link building',
                        'Monthly report'
                    ]
                },
                sem: {
                    advantages: [
                        'Increased website traffic',
                        'Cost-effective',
                        'Targeted audience',
                        'Measurable results',
                        'Increased brand awareness',
                        'Improved conversion rates',
                        'Better user experience',
                        'Competitive advantage',
                        'Flexibility',
                        'Long-term benefits'
                    ],
                    package: [
                        'Keyword research and analysis',
                        'Analysis of competitors\' advertising campaigns',
                        'Creating campaign ads',
                        'Monitoring and optimizing created ads',
                        'A\\B testing',
                        'Monthly report'
                    ]
                }
            }
        },
        about: {
            introduction: {
                text1:
                    'Javachip is a development company with a mission to help businesses and entrepreneurs bring their tech dreams to life. We\'re a team of tech wizards and creative geniuses who love to help businesses like yours take their online presence to the next level.',
                text2:
                    'We love nothing more than taking your vision and turning it into a reality. Whether you\'re looking for a snazzy new website, a mobile app that will take the world by storm, or some AI magic to make your life easier, we\'ve got you covered.',
                text3:
                    'We understand that every business is unique, and we\'re all about delivering tailored solutions that fit your specific needs. With our budget-friendly prices, lightning-fast turnaround times, and open and honest communication, we make it our mission to deliver results. ',
                headline:
                    'Make sure that the digital reflection of your business is in good hands because it is the first place where ' +
                    'your audience will meet your services and products.',
                mission: {
                    beginning: 'Our mission',
                    rest:
                        'is to provide professional digital services that will assist businesses, ' +
                        'individuals and non-profit organizations in promoting their brands and reaching out to a wide range of ' +
                        'potential customers all over the globe.'
                },
                vision: {
                    beginning: 'Our vision',
                    rest:
                        'is to be the world\'s leading provider of digital solutions by building ' +
                        'long-term customer relationships, developing top-notch talent, and continuing innovation.'
                }
            },
            why: "Why us",
            ourTeam: 'Our Team',
            reasons: {
                team: {
                    title: 'Dream team',
                    text:
                        'Our team of software wizards is the real deal! They have the skills, experience, and magic touch to make your project successful. With our dream team on your side, you can relax knowing your project is in good hands.'
                },
                schedule: {
                    title: 'Speed demon',
                    text:
                        'Time is money, and we get that. That\'s why we work fast to get your project completed in a jiffy. No dragging our feet or taking forever to get things done. We\'ll get the job done quickly, so you can get back to doing what you do best.',
                },
                security: {
                    title: 'Budget-friendly:',
                    text:
                        'Hey, we get it. Running a business is expensive. That\'s why we\'re all about keeping things budget-friendly without sacrificing quality. We\'ve got flexible pricing options and a transparent billing process that won\'t leave you feeling like you got ripped off.'
                },
                cost: {
                    title: 'Communication is key',
                    text:
                        'We believe that open and honest communication is the key to a successful project. That\'s why we\'ll keep you in the loop every step of the way, whether it\'s through regular updates, face-to-face meetings, or just a friendly chat.'
                },
                dev: {
                    title: 'Tailored to you',
                    text:
                        'No cookie-cutter solutions here! We know that every business is unique, and we\'re all about delivering custom software development services that fit your specific needs like a glove.'
                },
                assistance: {
                    title: 'Happy, happy, joy, joy',
                    text:
                        'We\'re confident that you\'re going to love what we do for you. In fact, we\'re so confident that we offer a satisfaction guarantee. If you\'re not happy with the results, we\'ll work with you until you are. Because, after all, a happy client is a happy us!'
                }
            },
            team: {
                huseynli: 'Chief Executive Officer',
                atayev: 'Head of Business Development',
                rahimov: 'Full stack Web Developer',
                tagizade: 'Full stack Web Developer',
                karimli: 'AI Team Leader',
                salimov: 'AI Developer',
                alakbarli: 'AI Developer',
                atakishiyeva: 'Digital Marketing Manager',
                guliyeva: 'Mobile App Developer',
                maharramova: 'UI/UX designer',
                aliyeva: 'Graphic Designer',
                sayilova: 'SMM Manager'
            }
        },
        contact: {
            head: 'Kindly fill the form with details regarding your inquiry. We’d love to collaborate with you.',
            form: {
                name: 'Your Name',
                email: 'Your Email',
                phone: 'Your Phone Number',
                services: 'Services',
                message: 'Share Your Message',
                button: 'Send your message'
            },
            title: 'We are HERE'
        },
        faq: {
            titles: {
                1: 'Do you build custom websites or use templates?',
                2: 'Do you work with local businesses only?',
                3: 'What are the examples of your AI services?',
                4: 'Is it possible to make money using my app?',
                5: 'Will you train me on how to use my website?',
                6: 'Do you provide ongoing support, maintenance and updates?',
                7: 'How can AI chatbots help me with customer service?',
                8: 'Are your web designs responsive?',
                9: 'What makes you different?'
            },
            texts: {
                1:
                    'We never use templates. At Javachip, we build all the websites ' +
                    'and other digital products from scratch.',
                2:

                    'We work with businesses all over the country and are happy to deliver our digital products ' +
                    'to international clients. Throughout the process, our team is glad to conduct conference calls, ' +
                    'video chats, and screen sharing so that we can communicate with your team as effectively as possible.',

                3: 'Chatbots, Computer vision, Predictive Analytics, Machine Learning, Natural Language Processing, IVR automation' +
                    'are some of the AI solutions we provide.',

                4:
                    'Yes. You can sell it as a paid app, you can ' +
                    'offer in-app purchases, or you can sell advertising space. You can also use your app to drive traffic to your website or blog.' +
                    'Whichever method you choose, you\'ll need to make sure you\'ve got a great app that people will want to use.' +
                    'If you\'re not sure where to start, don\'t worry - we can help.',

                5:
                    'Sure! After the completion of your website, we will teach you how to use CMS( content management system). ' +
                    'This allows you to make changes and updates on your website without knowing any coding.',
                6:
                    'Yes, we definitely provide ongoing support, maintenance and updates! We want to make sure that our products' +
                    'are always up-to-date and running smoothly, so we offer ongoing support to all of our clients. We also offer' +
                    'maintenance and updates for an additional fee. Just let us know what you need and we\'ll be happy to help!',

                7: 'AI chatbots can help reduce wait times by providing automated customer service. This means that customers can get ' +
                    'the help they need without having to wait on hold or for a human representative. ' +
                    'AI chatbots can also provide accurate information. This is because they can access a wide range of data and information sources.' +
                    'This means that they can provide accurate and up-to-date answers to customer questions.',

                8:
                    'All the websites we build are responsive. It means that they automatically adjust to fit the screen of whatever ' +
                    'device they\'re being viewed on, whether that\'s a desktop computer, a laptop, a tablet, or a smartphone',

                9:
                    'From the very beginning, we approach all our projects with the utmost care and precision. ' +
                    'We use cutting-edge technology to provide our clients with the greatest systems at affordable ' +
                    'prices. Customer satisfaction and our reputation are everything to us! ',
            },

        },
        privacy: {
            head: 'Your privacy is important to us',
            subhead:
                'Javachip LLC is committed to maintaining the confidentiality, security, and accuracy of your personal information. To that ' +
                'end, Javachip LLC has established a Privacy Policy to protect the privacy rights of its clients, employees, and others. ' +
                'This policy relates to the collection, use, and disclosure of personal information by the company, its partners, and employees.',
            consent: {
                title: 'Your Consent',
                text:
                    'This Privacy Policy governs our data collection through our network of Website (www.thejavachip.com), ' +
                    'processing and usage practices. It also describes your choices regarding the use, access, and correction ' +
                    'of your personal information. By using the Website, you consent to the data practices described in this Privacy Policy. ' +
                    'We use the information we collect only in compliance with this Privacy Policy. We will never sell your Personal Information to any third party. ' +
                    'If you do not agree with the data practices described in this Privacy Policy, you should not use the Website.'
            },
            disclaimer: {
                title: 'Personal Information',
                text:
                    'Information that you voluntarily submit to us and that identifies you personally, including contact information, ' +
                    'such as your name, e-mail address, phone number, and other information about yourself or your business. ' +
                    'Navigational Information refers to information about your computer and your visits to this Website such as your IP address, ' +
                    'geographical location, browser type, referral source, length of visit and pages viewed. '
            },
            informationUse: {
                title: 'Other Use of Your Information',
                text:
                    'We use the information you provide about yourself to provide services. We do not share this information  ' +
                    'with outside parties except to the extent necessary to complete your request for our services. ' +
                    'Javachip reserves the right to reveal this information pursuant to a valid subpoena or court order.' +
                    'We use return email addresses to answer the email we receive for tech support, customer service,' +
                    'tell-a-friend, email updates. Such addresses are not used for any other purpose and are not shared with outside parties.' +
                    'we never use or share the personally identifiable information provided to us online, via phone,' +
                    'or in person, in ways unrelated to the ones described above without also providing you an ' +
                    'opportunity to opt-out or otherwise prohibit such unrelated uses.'
            },
            informationSecurity: {
                title: 'Security of Information',
                text: 'To prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, we have put in place appropriate' +
                    'physical, electronic, and managerial procedures to safeguard and secure the information we collect online and offline.' +
                    'All online credit card transactions are performed only on secure servers through a secure SSL connection ' +
                    'and comply with the latest standards in the industry. All billing information, including credit cards information,' +
                    'are deleted upon termination of the project with the client.'
            },
            links: {
                title: 'Links to other websites',
                text:
                    'This website may provide links to third-party websites. Any such links are provided solely as a convenience to you.' +
                    'Javachip has no control over these websites or their content and does not assume any responsibility or liability for these websites.' +
                    'Javachip does not endorse or make any representations about these websites, or any information, materials,' +
                    'or products found thereon. If you access any of the third-party websites linked on this Web site, you do so at your own risk.'
            },
            terms: {
                title: 'Children\'s privacy',
                text: 'Protecting the privacy of the very young is especially important. For that reason, we never collect' +
                    'or maintain information at our website from those we actually know are under 13, and no part of ' +
                    'our website is structured to attract anyone under 13.'
            },
            access: {
                title: 'How you can access your Personal Information?',
                text:
                    'At any time, you may later review or update the personal information we have collected online from you by contacting us at: info@thejavachip.com'
            },
            contact: {
                title: 'Contact Information',
                text: 'If you have any questions regarding your personal information or our privacy policy, please contact us at: info@thejavachip.com'
            },
            changes: {
                title: 'Changes to the Privacy Policy',
                text:
                    'Javachip can modify this Privacy Policy at any time without prior notice to you by posting revised Privacy Policy on our sites.' +
                    'We encourage you to review this Privacy Policy periodically.'
            }
        }
    }
}

export default en;