const ru = {
    common: {
        services: 'Наши услуги',
        contact: 'Свяжитесь с нами',
        about: 'О нас',
        privacy: 'Политика конфиденциальности',
        blog: 'Блог',
        faq: 'FAQs',
        digital: 'Цифровой маркетинг',
        web: 'Веб-разработка',
        mobile: 'Мобильная разработка',
        cyber: 'Кибербезопасность',
        ai: 'Искусственный интеллект',
        why: 'Преимущества',
        process: 'Процесс',
        alert: 'Сообщение было отправлено...'
    },
    header: {
        home: 'Домашняя страница',
        portfolio: 'Портфолио'
    },
    footer: {
        logo: {
            head: '',
            subhead: 'Ваш цифровой партнер'
        },
        titles: {
            quickLinks: 'Ссылки',
            social: 'Подписывайтесь на нас'
        },
        links: {
            work: 'портфолио',
        }
    },
    pages: {
        blog: {
            share: 'Поделиться',
            allCategories: 'Все категории',
            search: 'Поиск',
            recentPosts: 'Недавние блоги',
            relatedPosts: 'Похожие блоги'
        },
        home: {
            titles: {
                techs: 'Технологии, которые мы используем',
            },
            taptomore: 'Узнать больше',
            quote: 'Вы на шаг ближе к проекту своей мечты. Запрос цитаты',
            introduction: {
                head: {
                    be: 'be',
                    different: 'Different'
                },
                subhead: {
                    get: 'Get',
                    more: 'more',
                    leads: 'leads',
                    boost: 'Boost',
                    rest: 'your sales'
                }
            },
            services: {
                heads: {
                    web: 'Шагните в будущее с веб-сайтом, который впечатляет',
                    mobile: 'Положите свой бизнес в их ладони',
                    ai: 'Обеспечьте будущее своему бизнесу и оставайтесь впереди конкурентов',
                    cyber: 'Спите спокойно по ночам с кибербезопасностью, которая прикрывает вашу спину',
                },
                texts: {
                    web: 'Попрощайтесь с желтыми страницами, потому что в эпоху цифровых технологий люди не тянутся к телефонной книге, когда им нужно найти компанию. Они тянутся к своим смартфонам. С хорошо продуманным и удобным веб-сайтом вы сможете представить свои товары и услуги в лучшем свете. А теперь представьте, что вы только что запустили свой новый веб-сайт, и трафик на него хлынул потоком. Ваши клиенты поражены дизайном, молниеносной загрузкой и легким пользовательским интерфейсом. Именно такой сайт мы можем создать для вас.',
                    mobile: 'Мир становится все более мобильным, и как владелец бизнеса вы должны быть там, где ваши клиенты. Поскольку все больше и больше людей используют свои смартфоны для всего, мобильное приложение может изменить правила игры для вашего бизнеса. С мобильным приложением ваши клиенты будут иметь простой и удобный доступ к вашему бизнесу, независимо от того, нужно ли им совершить покупку, записаться на прием или просто получить некоторую информацию. Отличное мобильное приложение будет поддерживать интерес ваших клиентов и привлекать их снова и снова. Кто знает, они могут даже рассказать своим друзьям и семье о вашем бизнесе!',
                    ai: 'Представьте себе: бизнес, который работает как хорошо смазанный механизм, в котором ИИ выполняет утомительные задачи и освобождает время, чтобы вы могли сосредоточиться на росте и инновациях. Бизнес, который имеет доступ к ценной информации и может принимать обоснованные решения, ведущие к успеху. Искусственный интеллект больше не является делом будущего — он уже здесь, и он меняет то, как мы ведем бизнес. Наши решения на основе искусственного интеллекта помогут вам оптимизировать процессы, повысить качество обслуживания клиентов и получить ценную информацию о вашем бизнесе. Давайте использовать мощь ИИ сегодня!',
                    cyber: 'Вы много работали, чтобы построить свой бизнес. Не позволяйте нарушению безопасности привести к краху всего. Как владелец бизнеса, вы понимаете важность защиты своего бизнеса от киберугроз. С ростом числа кибератак как никогда важно иметь надежные меры безопасности. Покажите своим клиентам, что вы цените их конфиденциальность и безопасность, инвестируя в первоклассные услуги кибербезопасности. Не оставляйте свой бизнес уязвимым. Инвестируйте в свое спокойствие и защитите свой бизнес с помощью наших услуг по кибербезопасности.',
                }
            },
            digital: {
                smm: {
                    head: 'Привлекайте потенциальных клиентов и увеличивайте продажи',
                    subhead: 'Платформы социальных сетей — это самый простой способ связаться с потенциальными клиентами и распространить информацию о вашей компании.',
                    button: 'РАЗВИВАЙТЕ свой бизнес вместе с нами'
                },
                seo: {
                    head: 'Оптимизируйте свой сайт и генерируйте органический трафик',
                    subhead: 'Поисковая оптимизация — это процесс оптимизации вашего веб-сайта для повышения его позиций на страницах результатов поисковых систем.',
                    button: 'Получите БЕСПЛАТНЫЙ аудит сайта прямо сейчас'
                },
                sem: {
                    head: 'Повысьте узнаваемость вашего бренда',
                    subhead: 'SEM — это онлайн-реклама, которая используется для повышения видимости веб-сайта на страницах результатов поисковых систем.',
                    button: 'Разместите свою рекламу сейчас'
                }
            },
            techs: 'Мы всегда ищем новые технологии, которые могут помочь нам работать умнее, быстрее и лучше, и мы всегда раздвигаем границы возможного.',
            end: {
                head: 'Улучшите свое цифровое присутствие, получите больше потенциальных клиентов и увеличьте продажи',
                text1: 'Не тратьте время',
                text2: 'Нажмите на часы и отправьте нам свой список пожеланий'
            }
        },
        services: {
            web: {
                text1: 'Как владелец бизнеса, вы знаете, насколько важен профессиональный и функциональный веб-сайт для привлечения клиентов и развития вашего бренда. Вот почему наши услуги веб-разработки адаптированы для удовлетворения ваших конкретных потребностей. Независимо от того, являетесь ли вы малым бизнесом, которому нужен простой сайт для брошюр, или крупным предприятием, которому требуется сложная платформа для электронной коммерции, у нас есть знания и опыт, чтобы добиться выдающихся результатов. Наша команда экспертов будет тесно сотрудничать с вами на каждом этапе пути, гарантируя, что ваше видение будет реализовано, а ваш веб-сайт станет истинным отражением вашего бизнеса.',
                text2: 'Мы понимаем, что хороший веб-сайт — это больше, чем просто привлекательный дизайн. Он должен быть функциональным, быстрым и отзывчивым. Наша команда разработчиков имеет опыт во всех аспектах разработки веб-сайтов, включая дизайн, программирование и оптимизацию. Мы позаботимся о том, чтобы ваш сайт не только отлично выглядел, но и безупречно функционировал на всех устройствах и платформах. В конце концов, наша цель — помочь вам создать веб-сайт, который понравится вашим клиентам. Мы хотим, чтобы ваш веб-сайт был продолжением вашего бренда, местом, где клиенты могут общаться с вашим бизнесом, и платформой для демонстрации ваших продуктов и услуг.',
                reasons: [
                    'Веб-сайт, которого вы заслуживаете: попрощайтесь с пресными, устаревшими веб-сайтами и поприветствуйте профессиональное современное онлайн-присутствие, которое сделает ваш бизнес блестящим.',
                    'Будьте предметом зависти ваших конкурентов! Хорошо разработанный веб-сайт может дать вам конкурентное преимущество в вашей отрасли и сделать вас успешным бизнесом в вашей нише.',
                    'Загружайте быстрее, конвертируйте больше: быстрый, отзывчивый веб-сайт означает довольных клиентов и увеличение конверсии.',
                    'Простое обслуживание: попрощайтесь со сложным управлением веб-сайтом, наше решение упрощает обновление и поддержку вашего веб-сайта.',
                    'Веб-сайт, который работает на вас 24 часа в сутки 7 дней в неделю. Ваш веб-сайт будет круглосуточным продавцом для вашего бизнеса, поэтому вы можете сосредоточиться на том, что у вас получается лучше всего.',
                    'Работает на любом устройстве: ваши клиенты всегда в пути, поэтому убедитесь, что ваш веб-сайт тоже. Наше решение оптимизировано для любого устройства, поэтому вы никогда не пропустите ни одной детали.'
                
                ]
            },
            mobile: {
                text1: 'Мир всегда в движении, и мы знаем, что за ним может быть сложно угнаться. Но не бойтесь, у нас есть решение, которое выведет ваш бизнес на новый уровень! Мы все знаем, что будущее за смартфонами, и поскольку все больше людей полагаются на свои телефоны практически во всем, мобильное приложение может изменить правила игры для вашего бизнеса. Не просто приложение, заметьте, а красивое и удобное для пользователя, которое позволяет вашим клиентам легко взаимодействовать с вашим бизнесом и получать доступ к продуктам и услугам, в которых они нуждаются.',
                text2: 'Вот где мы входим! Наша команда опытных разработчиков готова помочь вам создать индивидуальное мобильное приложение, которое соответствует вашим потребностям и превосходит ваши ожидания. Мы будем тесно сотрудничать с вами, чтобы ваше приложение было не только красивым, но и очень функциональным и простым в использовании. Мы считаем, что ваши клиенты заслуживают самого лучшего, и наши услуги по разработке мобильных приложений помогут вам дать им именно это. Имея приложение, доступное на ходу, вы можете обращаться к своим клиентам, где бы они ни находились, и вовлекать их в свой бизнес. С нашими услугами по разработке мобильных приложений вы сможете вывести свой бизнес на новый уровень и общаться со своими клиентами, как никогда раньше.',
                reasons: [
                    'Решение App-solute: будьте готовы вывести свой бизнес на новый уровень с помощью специально разработанного мобильного приложения!',
                    'Будьте крутым парнем на районе: выделитесь из толпы и дайте своим клиентам причину выбрать вас среди конкурентов с помощью элегантного и стильного мобильного приложения.',
                    'Удобное развлечение: попрощайтесь с неуклюжими веб-сайтами и познакомьтесь с удобным, веселым и интерактивным мобильным приложением, которое заставит ваших клиентов возвращаться снова и снова.',
                    'Дотянитесь до звезд: мобильное приложение может помочь вам охватить более широкую аудиторию, независимо от того, в какой части мира они находятся, давая вам возможность расширять охват и развивать свой бизнес.',
                    'Мобильное чудо: поразите своих клиентов молниеносной скоростью загрузки, простой навигацией и всеми необходимыми функциями прямо у них под рукой.',
                    'Всегда на связи: с мобильным приложением ваш бизнес будет всегда у ваших клиентов под рукой. И кто знает, они могут никогда не отпустить!'
                ]
            },
            ai: {
                text1: 'Представьте себе команду машин, которые могут учиться и работать самостоятельно, принимая обоснованные решения на основе вводимых данных. С ИИ эта мечта стала реальностью, а возможности безграничны! От здравоохранения до транспорта, логистики и банковского дела, от розничной торговли до телекоммуникаций — искусственный интеллект способен преобразовать ваши бизнес-операции и вывести вас на новые высоты успеха. Как владелец бизнеса, мы знаем, насколько важно иметь подходящие технологии и инструменты, которые помогут вашему бизнесу расти и добиваться успеха, и именно здесь мы вступаем в игру.',
                text2: 'Мы в Javachip стремимся помочь вам в достижении ваших бизнес-целей, предоставляя вам интеллектуальные и автоматизированные решения. Благодаря нашим передовым технологиям искусственного интеллекта теперь вы можете предложить своим клиентам персонализированный опыт, отвечающий их уникальным предпочтениям и потребностям. Если вам нужно машинное обучение, компьютерное зрение, обработка естественного языка (NLP), прогнозирование или оптимизация, мы обеспечим вас! Наша команда экспертов по искусственному интеллекту увлечена созданием инновационных решений, которые помогут вывести ваш бизнес на новый уровень. Позвольте нам помочь вам раскрыть всю мощь технологий и раскрыть весь свой потенциал!',
                reasons: [
                    'Более разумные бизнес-решения. С решениями на основе ИИ вы можете попрощаться с интуицией и принять решения, основанные на данных. ИИ может анализировать огромные объемы данных, чтобы помочь вам принимать обоснованные решения о вашем бизнесе.',
                    'Чтение мыслей: Хорошо, не совсем чтение мыслей, но близко! С помощью ИИ вы можете анализировать поведение клиентов, их предпочтения и отзывы, чтобы предоставлять персонализированный опыт и повышать удовлетворенность клиентов.',
                    'Повышение производительности: ИИ может автоматизировать многие рутинные задачи, высвобождая время для вас и вашей команды.',
                    'Сократите расходы, а не качество. Решения на основе ИИ могут помочь вам сократить расходы, сохраняя при этом высокое качество результатов. Представьте, что вам больше никогда не придется платить сверхурочные!',
                    'Будущее уже здесь: опережайте игру и смотрите в будущее с решениями ИИ. Будьте впереди конкурентов и будьте в курсе последних достижений в области технологий.',
                    'Предел неба: с ИИ возможности безграничны! От чат-ботов до предиктивной аналитики — нет предела совершенству, когда дело доходит до улучшения ваших бизнес-процессов с помощью решений ИИ.'
                
                ]
            },
            cyber: {
                text1: 'Важно помнить, что в современном цифровом мире каждая компания, присутствующая в Интернете, подвергается риску киберпреступности. Неважно, насколько велика или мала ваша организация, ваш веб-сайт и цифровые активы всегда уязвимы. К сожалению, последствия утечки данных могут быть невероятно разрушительными, затрагивая как ваших клиентов, так и ваш бизнес. Тревожно думать о миллионах людей, чья личная информация была скомпрометирована из-за кибератак, в результате которых был нанесен ущерб в миллиарды долларов.',
                text2: 'Но не волнуйтесь! Мы здесь, чтобы помочь. Мы знаем, что как владелец бизнеса вы сосредоточены на росте и процветании, и последнее, с чем вы хотите столкнуться, — это кибератака. Вот почему мы предлагаем комплексные услуги по кибербезопасности для защиты ваших систем. Наши услуги включают в себя все: от брандмауэров до антивирусной защиты, обнаружения вторжений и многого другого. С нашей помощью вы можете быть уверены, что ваш бизнес защищен от киберугроз и что вы можете сосредоточиться на том, что действительно важно — развитии вашего бизнеса.',
                reasons: [
                    'Спи спокойно, не дай себя взломать: защити свой бизнес и клиентов от киберугроз и лучше спи по ночам, зная, что ты в безопасности.',
                    'Обеспечьте безопасность своего онлайн-мира: защитите свою конфиденциальную информацию и оградите себя от киберугроз с помощью наших первоклассных решений для кибербезопасности.',
                    'Будьте героем для своих клиентов: покажите своим клиентам, что вы цените их конфиденциальность и безопасность, инвестируя в первоклассные услуги кибербезопасности.',
                    'Не будьте следующим заголовком: Будьте впереди игры и защитите свой бизнес от того, чтобы стать следующей целью кибератаки.',
                    'Защитите свой бизнес от взлома. Защитите свой бизнес и клиентов от разрушительных последствий утечки данных с помощью наших комплексных решений для обеспечения безопасности.',
                    'Кибербезопасность, а не ракетостроение: наши простые в использовании, но эффективные решения для кибербезопасности помогут упростить задачу.'
                ]
            },
            digital: {
                comment: 'Ежедневно интернет-пользователи выполняют миллиарды поисковых запросов. И если ваш веб-сайт хорошо оптимизирован ' +
                    'для поисковых систем, таких как Google, Bing, Яндекс и т. д., вы будете регулярно получать огромное количество целевого трафика.',
                package: 'Упаковка',
                advantages: 'Преимущества',
                seo: {
                    advantages: [
                        'Повышение видимости сайта',
                        'Более высокий рейтинг в поисковых системах',
                        'Увеличение посещаемости вашего сайта',
                        'Улучшенный пользовательский опыт',
                        'Повышение узнаваемости бренда',
                        'Улучшенный пользовательский опыт',
                        'Долгосрочная маркетинговая стратегия',
                        'Выше рентабельность инвестиций',
                        'Увеличение количества лидов и продаж',
                        'Конкурентное преимущество'
                    ],
                    package: [
                        'Аудит сайта',
                        'Индивидуальная SEO-стратегия',
                        'Исследование и анализ ключевых слов',
                        'Техническое SEO',
                        'SEO-блоги',
                        'Оптимизация изображения',
                        'Разметка схемы',
                        'SEO на странице',
                        'Семантическое SEO',
                        'Анализ конкурентов',
                        'Оптимизация контента',
                        'Аудит обратных ссылок',
                        'Линкбилдинг',
                        'Ежемесячный отчет'
                    ]
                },
                smm: {
                    advantages: [
                        'Повышение узнаваемости бренда',
                        'Улучшенное взаимодействие с клиентами',
                        'Увеличение посещаемости сайта',
                        'Лучший таргетинг на вашу аудиторию',
                        'Улучшение репутации бренда',
                        'Увеличение лидов и продаж',
                        'Повышение удовлетворенности клиентов',
                        'Лучшая окупаемость инвестиций',
                        'Конкурентное преимущество',
                        'Повышение лояльности клиентов'
                    ],
                    package: [
                        'Индивидуальная SMM-стратегия',
                        'Ежемесячный контент-план',
                        'Создание бизнес-аккаунтов и управление ими',
                        'Информативные и креативные посты',
                        'Создание рекламных кампаний',
                        'Автоматизированная система ответа на входящие звонки и сообщения',
                        'Профессиональная фото и видеосъемка',
                        'Организация увлекательных конкурсов',
                        'Ежемесячный отчет'
                    ]
                },
                sem: {
                    advantages: [
                        'Увеличение посещаемости сайта',
                        'Экономически эффективным',
                        'Целевая аудитория',
                        'Измеримые результаты',
                        'Повышение узнаваемости бренда',
                        'Улучшенные коэффициенты конверсии',
                        'Улучшенный пользовательский опыт',
                        'Конкурентное преимущество',
                        'Гибкость',
                        'Долгосрочные преимущества'
                    ],
                    package: [
                        'Исследование и анализ ключевых слов',
                        'Анализ рекламных кампаний конкурентов',
                        'Создание объявлений кампании',
                        'Мониторинг и оптимизация созданных объявлений',
                        'А\\В тестирование',
                        'Ежемесячный отчет'
                    ]
                }
            }
        },
        about: {
            introduction: {
                text1:
                    'Javachip — это компания-разработчик, миссия которой — помочь предприятиям и предпринимателям воплотить в жизнь свои технологические мечты. Мы команда технических волшебников и творческих гениев, которым нравится помогать таким компаниям, как ваша, вывести свое присутствие в Интернете на новый уровень.',
                text2:
                    'Мы любим не что иное, как взять ваше видение и воплотить его в реальность. Если вы ищете шикарный новый веб-сайт, мобильное приложение, которое покорит мир, или какую-нибудь магию искусственного интеллекта, которая облегчит вашу жизнь, мы обеспечим вас.',
                text3:
                    'Мы понимаем, что каждый бизнес уникален, и мы стремимся предоставлять индивидуальные решения, которые соответствуют вашим конкретным потребностям, как перчатка. Благодаря нашим бюджетным ценам, молниеносным срокам выполнения работ, а также открытому и честному общению, мы ставим перед собой задачу добиться результатов, которые заставят вас танцевать с удовольствием.',
                headline:
                    'Убедитесь, что цифровое отражение вашего бизнеса находится в надежных руках, потому что это первое место, ' +
                    'где ваша аудитория познакомится с вашими услугами и продуктами. ',
                mission: {
                    beginning: 'Наша миссия',
                    rest:
                        'предоставлять профессиональные цифровые услуги, которые помогут предприятиям, частным лицам и некоммерческим ' +
                        'организациям продвигать свои бренды и охватывать широкий круг потенциальных клиентов по всему миру. '
                },
                vision: {
                    beginning: 'Наше видение',
                    rest:
                        'стать ведущим мировым поставщиком цифровых решений, выстраивая долгосрочные отношения с клиентами, ' +
                        'развивая первоклассные кадры и продолжая инновации.'
                }
            },
            why: 'Почему нас',
            ourTeam: 'Наша Команда',
            reasons: {
                team: {
                    title: 'Профессиональная команда',
                    text:
                        'Наша команда волшебников программного обеспечения - это настоящее дело! У них есть навыки, опыт и магическое прикосновение, чтобы сделать ваш проект успешным. С нашей мечтательной командой на вашей стороне вы можете расслабиться, зная, что ваш проект в надежных руках.'
                },
                schedule: {
                    title: 'Скоростной демон',
                    text:
                        'Время - это деньги, и мы это понимаем. Вот почему мы работаем быстро, чтобы завершить ваш проект в мгновение ока. Никаких тяжелых ног или бесконечного ожидания выполнения работы. Мы быстро справимся с работой, чтобы вы могли вернуться к тому, что умеете делать лучше всего.'
                },
                security: {
                    title: 'Экономичный',
                    text:
                        'Эй, мы понимаем. Ведение бизнеса дорого. Вот почему мы все о том, чтобы сохранять все экономичным без жертвования качеством. У нас есть гибкие варианты ценообразования и прозрачный процесс выставления счетов, который не заставит вас чувствовать себя обманутым.'
                },
                cost: {
                    title: 'Коммуникация - ключ',
                    text:
                        'Мы считаем, что открытое и честное общение является ключом к успешному проекту. Вот почему мы будем держать вас в курсе каждого шага, будь то через регулярные обновления, личные встречи или просто дружеский разговор.'
                },
                dev: {
                    title: 'Индивидуальный подход',
                    text:
                        'Никаких шаблонных решений! Мы знаем, что каждый бизнес уникален, и мы все о предоставлении услуг по разработке программного обеспечения на заказ, которые подходят вашим конкретным потребностям как перчатка.'
                },
                assistance: {
                    title: 'Счастье, радость',
                    text:
                        'Мы уверены, что вам понравится то, что мы делаем для вас. На самом деле, мы настолько уверены, что предлагаем гарантию удовлетворенности. Если вы не довольны результатами, мы будем работать с вами до тех пор, пока вы не будете. Ведь счастливый клиент - это счастливый мы!'
                }
            },
            team: {
                huseynli: 'Исполнительный директор',
                atayev: 'Руководитель отдела развития бизнеса',
                rahimov: 'Full stack Веб-разработчик',
                tagizade: 'Full stack Веб-разработчик',
                karimli: 'Руководитель группы ИИ',
                salimov: 'Разработчик ИИ',
                alakbarli: 'Разработчик ИИ',
                atakishiyeva: 'Менеджер по цифровому маркетингу',
                guliyeva: 'Разработчик мобильных приложений',
                maharramova: 'UI/UX дизайнер',
                aliyeva: 'Графический дизайнер',
                sayilova: 'SMM-менеджер'
            }
        },
        contact: {
            head: 'Пожалуйста, заполните форму с подробной информацией о вашем запросе. Мы будем рады сотрудничать с вами.',
            form: {
                name: 'Ваше имя',
                email: 'Ваш адрес электронной почты',
                phone: 'Ваш номер телефона',
                services: 'Сервисы',
                message: 'Поделитесь своим сообщением',
                button: 'Отправить'
            },
            title: 'Мы тут'
        },
        faq: {
            titles: {
                1: 'Вы создаете собственные сайты или используете шаблоны?',
                2: 'Вы работаете только с местными предприятиями?',
                3: 'Каковы примеры ваших услуг ИИ?',
                4: 'Можно ли заработать с помощью моего приложения?',
                5: 'Вы обучите меня пользоваться моим веб-сайтом?',
                6: 'Оказываете ли вы постоянную поддержку, техническое обслуживание и обновления?',
                7: 'Как чат-боты с искусственным интеллектом могут помочь мне в обслуживании клиентов?',
                8: 'Отзывчив ли ваш веб-дизайн?',
                9: 'Что отличает вас от других?'
            },
            texts: {
                1: 'Мы никогда не используем шаблоны. В Javachip мы создаем все веб-сайты и мобильные приложения с нуля.',
                2: 'Мы работаем с предприятиями по всей стране и рады поставлять наши цифровые продукты международным клиентам.' +
                    'На протяжении всего процесса наша команда рада проводить конференц-звонки, видеочаты и совместное использование ' +
                    'экрана, чтобы мы могли максимально эффективно общаться с вашей командой.',
                3: 'Чат-боты, компьютерное зрение, предиктивная аналитика, машинное обучение, обработка естественного языка, автоматизация IVR — вот некоторые из решений ИИ, которые мы предоставляем.',
                4: 'Да. Вы можете продавать его как платное приложение, предлагать покупки в приложении или продавать рекламное место. Вы также можете использовать ' +
                    'свое приложение для привлечения трафика на свой веб-сайт или блог. Какой бы метод вы ни выбрали, вам нужно убедиться, ' +
                    'что у вас есть отличное приложение, которое люди захотят использовать. Если вы не знаете, с чего начать, не беспокойтесь — мы можем помочь.',
                5: 'Конечно! После завершения вашего веб-сайта мы научим вас, как использовать CMS (система управления контентом). Это позволяет вам вносить изменения и обновления на свой сайт, не зная программирования.',
                6: 'Да, мы определенно обеспечиваем постоянную поддержку, техническое обслуживание и обновления! Мы хотим, чтобы наши продукты всегда были актуальными и работали бесперебойно, ' +
                    'поэтому мы предлагаем постоянную поддержку всем нашим клиентам. Мы также предлагаем обслуживание и обновления за дополнительную плату. ' +
                    'Просто дайте нам знать, что вам нужно, и мы будем рады помочь!',
                7: 'Чат-боты с искусственным интеллектом могут помочь сократить время ожидания, обеспечивая автоматизированное обслуживание клиентов. ' +
                    'Это означает, что клиенты могут получить необходимую им помощь, не дожидаясь ожидания представителя человека. Чат-боты с искусственным ' +
                    'интеллектом также могут предоставлять точную информацию. Это связано с тем, что они могут получить доступ к широкому спектру данных и ' +
                    'источников информации. Это означает, что они могут предоставить точные и актуальные ответы на вопросы клиентов.',
                8: 'Все сайты, которые мы создаем, адаптивны. Это означает, что они автоматически подстраиваются под экран любого устройства, на котором их просматривают, будь то настольный компьютер, ноутбук, планшет или смартфон.',
                9: 'С самого начала мы подходим ко всем нашим проектам с максимальной тщательностью и точностью. Мы используем передовые технологии, чтобы предоставить нашим клиентам лучшие системы по доступным ценам. ' +
                    'Удовлетворение потребностей клиентов и наша репутация для нас все!'
            },

        },
        privacy: {
            head: 'Ваша конфиденциальность важна для нас',
            subhead: 'ООО «Джавачип» стремится поддерживать конфиденциальность, безопасность и точность вашей личной информации. С этой целью компания Javachip LLC разработала Политику конфиденциальности для защиты прав на неприкосновенность частной жизни своих клиентов, сотрудников и других лиц. Эта политика касается сбора, использования и раскрытия личной информации фирмой, ее партнерами и сотрудниками.',
            consent: {
                title: 'Ваше согласие',
                text: 'Настоящая Политика конфиденциальности регулирует сбор данных через нашу сеть веб-сайтов (www.thejavachip.com), методы обработки и использования. В нем также описывается ваш выбор в отношении использования, доступа и исправления вашей личной информации. Используя Веб-сайт, вы соглашаетесь с методами работы с данными, описанными в настоящей Политике конфиденциальности. Мы используем информацию, которую собираем, только в соответствии с настоящей Политикой конфиденциальности. Мы никогда не будем продавать вашу личную информацию какой-либо третьей стороне. Если вы не согласны с методами работы с данными, описанными в настоящей Политике конфиденциальности, вы не должны использовать Веб-сайт.'
            },
            disclaimer: {
                title: 'Персональные данные',
                text: 'Информация, которую вы добровольно предоставляете нам и которая идентифицирует вас лично, включая контактную информацию, такую как ваше имя, адрес электронной почты, номер телефона и другую информацию о вас или вашем бизнесе. Навигационная информация относится к информации о вашем компьютере и ваших посещениях этого Веб-сайта, такой как ваш IP-адрес, географическое положение, тип браузера, источник перехода, продолжительность посещения и просмотренные страницы.'
            },
            informationUse: {
                title: 'Другое использование вашей информации',
                text: 'Мы используем предоставленную вами информацию о себе для предоставления услуг. Мы не передаем эту информацию сторонним лицам, за исключением случаев, когда это необходимо для выполнения вашего запроса на наши услуги. Javachip оставляет за собой право раскрывать эту информацию в соответствии с действительной повесткой в суд или постановлением суда. Мы используем обратные адреса электронной почты, чтобы отвечать на электронные письма, которые мы получаем для технической поддержки, обслуживания клиентов, обновлений по электронной почте. Такие адреса не используются для каких-либо других целей и не передаются третьим сторонам. Мы никогда не используем и не передаем личную информацию, предоставленную нам в Интернете, по телефону или лично, способами, не связанными с описанными выше, без предоставления вам возможности отказаться или иным образом запретить такое несвязанное использование.'
            },
            informationSecurity: {
                title: 'Безопасность информации',
                text: 'Чтобы предотвратить несанкционированный доступ, обеспечить точность данных и обеспечить правильное использование информации, мы внедрили соответствующие физические, электронные и управленческие процедуры для защиты и защиты информации, которую мы собираем онлайн и офлайн. Все онлайн-транзакции по кредитным картам выполняются только на защищенных серверах через безопасное SSL-соединение и соответствуют последним стандартам отрасли. Вся платежная информация, включая информацию о кредитных картах, удаляется по завершении проекта с клиентом.'
            },
            links: {
                title: 'Ссылки на другие сайты',
                text: 'Этот веб-сайт может содержать ссылки на сторонние веб-сайты. Любые такие ссылки предоставляются исключительно для вашего удобства. Javachip не контролирует эти веб-сайты или их содержимое и не несет никакой ответственности за эти веб-сайты. Javachip не поддерживает и не делает никаких заявлений об этих веб-сайтах или любой информации, материалах или продуктах, найденных на них. Если вы заходите на какой-либо из сторонних веб-сайтов, на которые есть ссылки на этом веб-сайте, вы делаете это на свой страх и риск.'
            },
            terms: {
                title: 'Конфиденциальность детей',
                text: 'Особенно важно защищать частную жизнь самых маленьких. По этой причине мы никогда не собираем и не храним информацию на нашем веб-сайте от тех, кому, как мы знаем, еще не исполнилось 13 лет, и никакая часть нашего веб-сайта не предназначена для привлечения лиц моложе 13 лет.'
            },
            access: {
                title: 'Как вы можете получить доступ к своей личной информации?',
                text: 'В любое время вы можете позже просмотреть или обновить личную информацию, которую мы получили от вас в Интернете, связавшись с нами по адресу: info@thejavachip.com'
            },
            contact: {
                title: 'Контакты',
                text: 'Если у вас есть какие-либо вопросы относительно вашей личной информации или нашей политики конфиденциальности, свяжитесь с нами по адресу: info@thejavachip.com'
            },
            changes: {
                title: 'Изменения в Политике конфиденциальности',
                text: 'Javachip может изменить настоящую Политику конфиденциальности в любое время без предварительного уведомления, разместив пересмотренную Политику конфиденциальности на наших сайтах. Мы рекомендуем вам периодически просматривать эту Политику конфиденциальности.'
            }
        }
    }
}

export default ru;